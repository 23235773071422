const ROUTES = {
  generalResults: {
    path: "/",
    title: "General results",
  },
  detailedResults: {
    path: "/detailed-results/",
    title: "Detailed results",
  },
  detailedResultsExplanation: {
    path: "/detailed-results-explanation/",
    title: "Detailed results explanation",
  },
  groupComparison: {
    path: "/group-comparison/",
    title: "Group comparison",
  },
  departmentImpact: {
    path: "/department-impact/",
    title: "Department Impact",
  },
  positionImpact: {
    path: "/position-impact/",
    title: "Position Impact",
  },
  ageImpact: {
    path: "/age-impact/",
    title: "Age Impact",
  },
};

export { ROUTES };
