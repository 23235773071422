import React from "react";
import Chart from "react-apexcharts";
import "./GeneralResults.scss";

interface ChartProps {
  apiData: any | null;
}

const GeneralResults = ({ apiData }: ChartProps) => {
  const options = {
    chart: {
      id: "radar-chart",
      toolbar: {
        show: false,
      },
      stackOnlyBar: false,
      offsetX: 68,
      offsetY: 40,
    },

    stroke: {
      show: true,
      colors: ["#BEBEBE", "#79C584"],
      width: 1,
    },

    markers: {
      size: 6,
      colors: "#F0F0F0",
      strokeColors: ["#BEBEBE", "#79C584"],
      strokeWidth: 2,
    },
    legend: {
      show: true,
      position: "right",
      fontFamily: "Inter",
      fontWeight: 400,
      fontSize: 15,
      labels: {
        colors: ["#929292", "#79C584"],
      },
      itemMargin: {
        horizontal: 0,
        vertical: 5,
      },
      markers: {
        width: 21,
        height: 21,
        strokeWidth: 2,
        radius: 34,
        strokeColor: ["#C1C1C1", "#79C584"],
        fillColors: ["#fff", "#fff"],
      },
    },
    fill: {
      colors: ["#BEBEBE", "#79C584"],
      opacity: 0.2,
    },
    dataLabels: {
      enabled: true,
      offsetY: 21,
      offsetX: 0,
      style: {
        fontSize: "11px",
        fontWeight: "bold",
        colors: ["#A1A1A1", "#393939"],
      },
      background: {
        enabled: false,
      },
    },
    plotOptions: {
      radar: {
        polygons: {
          strokeColors: "#fff",
        },
      },
    },
    xaxis: {
      categories: [
        "Brand Influence",
        "Products & Services",
        "Purpose",
        "Opperations & Supply Chain",
        "Governance",
      ],
      labels: {
        show: false,
      },
    },
    grid: {
      show: false,
    },
  };

  return (
    <>
      {apiData && (
        <div className="general-results-container">
          <div
            className="general-results-chart"
            style={{
              backgroundImage:
                'url("https://destaticfiles.z6.web.core.windows.net/sustainable-brands-report/chart.png")',
            }}
          >
            <Chart
              // @ts-ignore
              options={{ ...options }}
              series={apiData}
              type="radar"
              height="400"
            />
          </div>
          <div className="text-description-container">
            <div className="text">
              <b>Say</b> - percentage of people who declared positive opinion
            </div>
            <div className="text">
              <b>Do</b> - percentage of people with firm positive opinion
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default GeneralResults;
