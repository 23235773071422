import React from "react";

const NoFound = ({ text = "Data not found" }) => {
  return (
    <div style={{ padding: "100px 50px 25px" }} className="fadeIn">
      {text}
    </div>
  );
};

export default NoFound;
