/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, createContext } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/Header/Header";
import Loader from "./components/Loader/Loader";
import AppLoader from "./components/AppLoader/AppLoader";
import GeneralResultsPage from "./pages/GeneralResultsPage/GeneralResultsPage";
import AppContainer from "./components/AppContainer/AppContainer";
import NoFound from "./components/NoFound/NoFound";
import { ROUTES } from "./constants";
import DetailedResultsPage from "./pages/DetailedResultsPage/DetailedResultsPage";
import DetailedResultsExplanationPage from "./pages/DetailedResultsExplanationPage/DetailedResultsExplanationPage";
import { apiCallQuery } from "./api/apiCall";
import GroupComparisonPage from "./pages/GroupComparisonPage/GroupComparisonPage";
import RoleImpactPage from "./pages/DepartmentImpactPage/DepartmentImpactPage";
import PositionImpactPage from "./pages/PositionImpactPage/PositionImpactPage";
import AgeImpactPage from "./pages/AgeImpactPage/AgeImpactPage";
// import { mock } from "./api/mock";

export const AppContext = createContext<any>(null);

const App = () => {
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState<boolean>(true);
  const [apiCall, setApiCall] = useState<any | null>(null);

  const appStart = async () => {
    const query = await apiCallQuery();
    setApiCall(query);
    setLoading(false);
  };

  useEffect(() => {
    appStart();
  }, []);

  const appContextValue = { title, setTitle, apiCall };

  return (
    <AppContext.Provider value={appContextValue}>
      {loading && <Loader />}
      <div
        className={`app ${!loading ? "fadeIn" : ""}`}
        style={{
          backgroundImage:
            "url(https://destaticfiles.z6.web.core.windows.net/sustainable-brands-report/bg.jpg)",
          display: `${loading ? "none" : "block"}`,
        }}
      >
        <Header />

        <AppContainer>
          <BrowserRouter>
            <Routes>
              <Route
                path={ROUTES.generalResults.path}
                element={<GeneralResultsPage />}
              />
              <Route
                path={ROUTES.detailedResults.path}
                element={<DetailedResultsPage />}
              />
              <Route
                path={`${ROUTES.detailedResultsExplanation.path}:codename`}
                element={<DetailedResultsExplanationPage />}
              />
              <Route
                path={ROUTES.groupComparison.path}
                element={<GroupComparisonPage />}
              />
              <Route
                path={ROUTES.departmentImpact.path}
                element={<RoleImpactPage />}
              />
              <Route
                path={ROUTES.positionImpact.path}
                element={<PositionImpactPage />}
              />
              <Route path={ROUTES.ageImpact.path} element={<AgeImpactPage />} />
              <Route path="*" element={<NoFound text="Page not found" />} />
            </Routes>
          </BrowserRouter>
        </AppContainer>
      </div>
      <AppLoader />
    </AppContext.Provider>
  );
};

export default App;
