import React from "react";
import "./DetailedResults.scss";
import { IDetailedResults } from "../../ts";
import { Link, useParams } from "react-router-dom";
import { ROUTES } from "../../constants";

interface DetailedResultsProps {
  apiData: IDetailedResults[] | null;
  explanation?: boolean;
}

const DetailedResults = ({
  apiData = null,
  explanation = false,
}: DetailedResultsProps) => {
  const params = useParams();
  return (
    <div
      className={`detailed-results-container  ${
        explanation ? "explanation-main-container" : ""
      }`}
    >
      {explanation && (
        <div className="brand">
          <img
            src={`https://destaticfiles.z6.web.core.windows.net/sustainable-brands-report/${params?.codename}.png`}
            alt=""
          />
        </div>
      )}
      <div className="wrapper">
        <div className="section">
          <div className="side">
            <div className="top">Say</div>
          </div>
          <div className="center">
            <div className="top">Do</div>
          </div>
          <div className="side">
            <div className="top">Say</div>
          </div>
        </div>
        <div className="section">
          <div className="side">
            <div className="bottom-side">
              Yes
              <span>(%)</span>
            </div>
          </div>
          <div className="center">
            <div className="bottom-center">
              <div className="item">
                <div className="item-wrapper">
                  <div className="box green"></div>
                  <div className="title">ACT</div>
                </div>
              </div>
              <div className="item">
                <div className="item-wrapper">
                  <div className="box gray"></div>
                  <div className="title">PTC</div>
                </div>
              </div>
              <div className="item">
                <div className="item-wrapper">
                  <div className="box red"></div>
                  <div className="title">BLOCK</div>
                </div>
              </div>
            </div>
          </div>
          <div className="side">
            <div className="bottom-side">
              No
              <span>(%)</span>
            </div>
          </div>
        </div>
      </div>
      {!explanation ? (
        <>
          {apiData?.map(
            ({ title, codename, yes, act, ptc, block, no }, index: number) => (
              <div className="wrapper main" key={index}>
                <div className="title-container logo-container">
                  <img
                    src={`https://destaticfiles.z6.web.core.windows.net/sustainable-brands-report/${codename}.png`}
                    alt=""
                  />
                  {title}
                </div>
                <div className="section">
                  <div className="side">
                    <div className="side-title">{yes}</div>
                  </div>
                  <div className="center">
                    <div className="side-description">
                      <div
                        className="side-item green"
                        style={{ width: `calc(${act}% + 36px)` }}
                      >
                        {act}
                      </div>
                      <div
                        className="side-item gray item-border"
                        style={{ width: `calc(${ptc}% + 36px)` }}
                      >
                        {ptc}
                      </div>
                      <div
                        className="side-item red"
                        style={{ width: `calc(${block}% + 36px)` }}
                      >
                        {block}
                      </div>
                    </div>
                  </div>
                  <div className="side">
                    <div className="side-title">{no}</div>
                  </div>

                  <Link
                    className="anchor"
                    to={`${ROUTES.detailedResultsExplanation.path}${codename}`}
                  >
                    <img
                      src="https://destaticfiles.z6.web.core.windows.net/sustainable-brands-report/arrow.png"
                      alt=""
                    />
                  </Link>
                </div>
              </div>
            )
          )}
        </>
      ) : (
        <>
          {apiData?.map(
            ({ title, yes, act, ptc, block, no }, index: number) => (
              <div className="wrapper main" key={index}>
                <div
                  className={`title-container explanation-container ${
                    index === 0 ? "title-container-first" : ""
                  }`}
                >
                  {title}
                </div>
                <div className="section">
                  <div className="side">
                    <div className="side-title">{yes}</div>
                  </div>
                  <div className="center">
                    <div className="side-description">
                      <div
                        className="side-item green"
                        style={{ width: `calc(${act}% + 36px)` }}
                      >
                        {act}
                      </div>
                      <div
                        className="side-item gray item-border"
                        style={{ width: `calc(${ptc}% + 36px)` }}
                      >
                        {ptc}
                      </div>
                      <div
                        className="side-item red"
                        style={{ width: `calc(${block}% + 36px)` }}
                      >
                        {block}
                      </div>
                    </div>
                  </div>
                  <div className="side">
                    <div className="side-title">{no}</div>
                  </div>
                </div>
              </div>
            )
          )}
        </>
      )}

      <div className="text-description-container">
        <div className="text">
          <b>Say</b> - percentage of people who declared positive/negative
          opinion
        </div>
        <div className="text-container">
          <div className="text">
            <b>Do - ACT/BLOCK</b> - percentage of people with firm
            positive/negative opinion
          </div>
          <div className="text">
            <b>PTC</b> - percentage of people who hesitate and therefore are
            Prone To Change
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailedResults;
