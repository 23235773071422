import React, { useContext } from "react";
import styles from "./InfoBox.module.scss";
import { AppContext } from "../../App";

const InfoBox = () => {
  const ctx = useContext(AppContext);
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const d = new Date();
  const getImage = () => {
    if (process.env.REACT_APP_VERSION === "pl") return "4-pl.png";
    if (process.env.REACT_APP_VERSION === "en") return "4.png";
  };
  return (
    <div className={styles.infoBox}>
      <img
        className={styles.infoBox_img1}
        src="https://destaticfiles.z6.web.core.windows.net/sustainable-brands-report/1.png"
        alt=""
      />
      <img
        className={styles.infoBox_img2}
        src={`https://destaticfiles.z6.web.core.windows.net/sustainable-brands-report/${getImage()}`}
        alt=""
      />
      <div className={styles.infoBox_text}>
        N={ctx?.apiCall?.quantity || 0}, {month[d.getMonth()]} {d.getFullYear()}
      </div>
    </div>
  );
};

export default InfoBox;
