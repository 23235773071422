import React from "react";
import "./ImpactView.scss";

const ImpactView = ({
  apiData,
  impactViewClassName = "",
}: {
  apiData: any;
  impactViewClassName?: string;
}) => {
  return (
    <div className={`impactView ${impactViewClassName}`}>
      <div className="ivRow">
        <div className="ivTitleView"></div>
        <div className="ivActView">
          <div className="ivMainTitle">Act</div>
          <div className="ivParams">
            {apiData?.params?.map((item: string, index: number) => (
              <div className="ivParam" key={index}>
                <div className="ivParamTitle">{item}</div>
                <div className="ivParamDescription">X=</div>
              </div>
            ))}
          </div>
        </div>
        <div className="ivBlockView">
          <div className="ivMainTitle">Block</div>
          <div className="ivParams">
            {apiData?.params?.map((item: string, index: number) => (
              <div className="ivParam" key={index}>
                <div className="ivParamTitle">{item}</div>
                <div className="ivParamDescription">X=</div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {apiData?.details?.map((item: any, index: number) => (
        <div className="ivRow" key={index}>
          <div className="ivTitleView">
            <div className="ivTitle">
              <img
                src={`https://destaticfiles.z6.web.core.windows.net/sustainable-brands-report/${item.codename}.png`}
                alt=""
              />
              {item.title}
            </div>
          </div>
          <div className="ivActView">
            <div className="ivParams">
              {item?.act?.map((item: any, index: number) => (
                <div className="ivParam" key={index}>
                  <div className="ivParamArea">
                    <div
                      className="ivParamDeclared ivParamDeclaredPositive"
                      style={{ height: `${item?.declaredPositive}%` }}
                    >
                      <div
                        className={`ivParamDeclaredPercentage${
                          Number(item?.declaredPositive) -
                            Number(item?.firmPositive) <=
                          12
                            ? "ivParamDeclaredPercentageTop"
                            : ""
                        }
                          ${
                            Number(item?.declaredPositive) > 90
                              ? "ivParamDeclaredPercentageBottom"
                              : ""
                          } ${
                          Number(item?.declaredPositive) === 0
                            ? "ivParamDeclaredPercentageHide"
                            : ""
                        }`}
                      >
                        {item?.declaredPositive}%
                      </div>
                    </div>
                    <div
                      className={`ivParamFirm ivParamFirmPositive ${
                        Number(item?.firmPositive) === 0 &&
                        Number(item?.declaredPositive) === 0
                          ? "ivParamFirmReset"
                          : ""
                      }`}
                      style={{ height: `${item?.firmPositive}%` }}
                    >
                      <div className="ivParamFirmPercentage">
                        {item?.firmPositive}%
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="ivBlockView">
            <div className="ivParams">
              {item?.block?.map((item: any, index: number) => (
                <div className="ivParam" key={index}>
                  <div className="ivParamArea">
                    <div
                      className="ivParamDeclared ivParamDeclaredNegative"
                      style={{ height: `${item?.declaredNegative}%` }}
                    >
                      <div
                        className={`ivParamDeclaredPercentage 
                        
                        ${
                          Number(item?.declaredNegative) -
                            Number(item?.firmNegative) <=
                          12
                            ? "ivParamDeclaredPercentageTop"
                            : ""
                        }
                        
                        ${
                          Number(item?.declaredNegative) > 90
                            ? "ivParamDeclaredPercentageBottom"
                            : ""
                        } ${
                          Number(item?.declaredNegative) === 0
                            ? "ivParamDeclaredPercentageHide"
                            : ""
                        }`}
                      >
                        {item?.declaredNegative}%
                      </div>
                    </div>
                    <div
                      className={`ivParamFirm ivParamFirmNegative ${
                        Number(item?.firmNegative) === 0 &&
                        Number(item?.declaredNegative) === 0
                          ? "ivParamFirmReset"
                          : ""
                      }`}
                      style={{ height: `${item?.firmNegative}%` }}
                    >
                      <div className="ivParamFirmPercentage">
                        {item?.firmNegative}%
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
      <div className="ivDescriptionView">
        <div className="ivDescriptionItem">
          <div className="ivDescriptionBox">
            <div className="ivDescriptionBoxItem ivParamDeclaredPositive"></div>
            <div className="ivDescriptionBoxItemText">/</div>
            <div className="ivDescriptionBoxItem ivParamDeclaredNegative"></div>
          </div>
          <div className="ivDescriptionTitle">
            <strong>Say</strong> - percentage of people who declared
            positive/negative opinion
          </div>
        </div>
        <div className="ivDescriptionItem">
          <div className="ivDescriptionBox">
            <div className="ivDescriptionBoxItem ivParamFirmPositive"></div>
            <div className="ivDescriptionBoxItemText">/</div>
            <div className="ivDescriptionBoxItem ivParamFirmNegative"></div>
          </div>
          <div className="ivDescriptionTitle">
            <strong>Act/Block</strong> - percentage of people with firm
            positive/negative opinion
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImpactView;
